import axios from 'axios';
import { Message } from 'element-ui'

// 接口路径
// export const baseUrl = 'http://192.168.1.175:8080'
// export const baseUrl = 'http://39.106.93.56'
export const baseUrl = 'https://server.hnjucailed.com'

// 创建axios实例
const service = axios.create({
    timeout: 30000 // 请求超时时间
})

// request请求拦截器
service.interceptors.request.use(config => {
      return config;
})

// request响应拦截器
service.interceptors.response.use(
    response => {
        let res = response.data
            // 返回处理
        if (res.code == 0 || res.code == 200) {
            if (!res.data) {
                res.data = res.msg
            }
            return res;
        }else {
            Message.close()
            if(res.msg)Message({
                type:'error',
                message:res.msg
            })
            return Promise.reject();
        }
    }, 
    error => {
        Message.close()
        Message({
            type:'error',
            message:'加载失败'
        })
        return Promise.reject(error)
    }
)

// 封装请求
function request(method, url, data = {}) {
    let sendObject = {
        url: baseUrl + url,
        method: method,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
    };
    if (method == 'post') {
        sendObject.data = JSON.stringify(data)
    } else if (method == 'get') {
        sendObject.params = data
    }
    return service(sendObject)
}
export function postRequest(url, data = {}) {
    return request('post', url, data)
}
export function getRequest(url, data = {}) {
    return request('get', url, data)
}