import Vue from 'vue'
import App from './App.vue'
import router from  './router'
import 'amfe-flexible'
import "./fonts/iconfont.css"
import {postRequest,getRequest} from "./js/http.js"
import './css/animate.css'
import wow from 'wowjs'
import 'element-ui/lib/theme-chalk/reset.css'
import 'element-ui/lib/theme-chalk/index.css'
Vue.prototype.$wow = wow
Vue.config.productionTip = false
Vue.prototype.$post = postRequest
Vue.prototype.$get = getRequest
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')