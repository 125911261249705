import Vue from 'vue'
import VueRouter from 'vue-router'// 解决 NavigationDuplicated: Avoided redundant navigation to current location 报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

// 路由表
const routes = [
  {
    path: '/',
    name: 'index',
    component: resolve => require(['@/view/index'],resolve)
  },
  {
    path: '/product/list',
    name: 'productList',
    component: resolve => require(['@/view/productList'],resolve)
  },
  {
    path: '/product/detail',
    name: 'productDetail',
    component: resolve => require(['@/view/productDetail'],resolve)
  },
  {
    path: '/solution/list',
    name: 'solutionList',
    component: resolve => require(['@/view/solutionList'],resolve)
  },
  {
    path: '/solution/detail',
    name: 'solutionDetail',
    component: resolve => require(['@/view/solutionDetail'],resolve)
  },
  {
    path: '/case/list',
    name: 'caseList',
    component: resolve => require(['@/view/caseList'],resolve)
  },
  {
    path: '/case/detail',
    name: 'caseDetail',
    component: resolve => require(['@/view/caseDetail'],resolve)
  },
  {
    path: '/service',
    name: 'service',
    component: resolve => require(['@/view/service'],resolve)
  },
  {
    path: '/salePoint',
    name: 'salePoint',
    component: resolve => require(['@/view/salePoint'],resolve)
  },
  {
    path: '/company',
    name: 'company',
    component: resolve => require(['@/view/company'],resolve)
  },
  {
    path: '/introduction',
    name: 'introduction',
    component: resolve => require(['@/view/introduction'],resolve)
  },
  {
    path: '/joinUs',
    name: 'joinus',
    component: resolve => require(['@/view/joinus'],resolve)
  },
  {
    path: '/news',
    name: 'news',
    component: resolve => require(['@/view/news'],resolve)
  },
  {
    path: '/news/detail',
    name: 'newsDetail',
    component: resolve => require(['@/view/newsDetail'],resolve)
  },
  {path:'*',redirect:"/"}
]
const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
})
router.beforeEach(async (to, from, next) => {
  next()
})
export default router